export const FONT_SIZE = {
  SMALL: 17,
  MEDIUM: 19,
  LARGE: 41,
};

export const IMAGE_NAMES = {
  BG_CERT: "bgCert",
  SIGN: "sign",
};

export const CERTIFICATE_DESCRIPTION: string[] = [
  "Успішно підтвердив(ла) свій рівень володіння",
  "необхідними (базовими) знаннями у сфері",
  "публічних закупівель на веб-порталі",
  "Уповноваженого органу.",
];

export const COLOR_WHITE = "#fff";

export const FONT_OPEN_SANS = "OpenSans";

export const PDF_CERT_NAME = "prozorro_certificate.pdf";

export const TEXTS = {
  CERT: "СЕРТИФІКАТ",
  DIRECTOR: "Микола Ткаченко",
};
