
































































































































import Component, { mixins } from "vue-class-component";
import { namespace } from "vuex-class";
import { QuestionKitsMixin } from "@/mixins/QuestionKitsMixin";
import Loader from "@/components/UI/Loader.vue";
import MaterialIcon from "@/components/UI/MaterialIcon.vue";
import ParamInfo from "@/components/UI/ParamInfo.vue";
import TimeView from "@/components/UI/TimeView.vue";
import AnswerStatusBar from "@/components/UI/AnswerStatusBar.vue";
import ExamsStatusBar from "@/components/ExamsStatusBar.vue";
import Modal from "@/components/UI/Modal.vue";
import { Route, NavigationGuardNext } from "vue-router";
import { UserInfoType } from "@/types/API/UserInfoType";
import { ObjectOf } from "@/types/ObjectOf";
import { ExamDescriptionType } from "@/types/API/ExamDescriptionType";
import { DateFormatPreview } from "@/services/DateFormat/DateFormatPreview";
import { EXAM_STATUS_CONFIG } from "@/config/examStatusConfig";
import { RightAnswerType } from "@/types/API/AnswersTypes";
import { STATUS_SUCCESS } from "@/constants/testStatuses";
import { PDF } from "@/services/PDF/PDF";
import {
  USER_EXAM_DESCRIPTION,
  USER_MANAGER_MODULE,
} from "@/constants/storeNames";
import { UserCertificateDataType } from "@/types/API/UserCertificateDataType";

const examDesc = namespace(USER_EXAM_DESCRIPTION);
const userManager = namespace(USER_MANAGER_MODULE);

@Component({
  components: {
    AnswerStatusBar,
    ExamsStatusBar,
    MaterialIcon,
    ParamInfo,
    TimeView,
    Loader,
    Modal,
  },
  filters: {
    formatDate(date: string): string {
      return DateFormatPreview.getFullDate(date);
    },
  },
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
    next((vm: any) => {
      vm.fromRoute = from;
    });
  },
})
export default class UserExamDescription extends mixins(QuestionKitsMixin) {
  private readonly pdf = new PDF();
  private fromRoute: Route | ObjectOf<any> = {};
  public signErrors = {};
  public showPreview = false;
  public loadingCertData = false;

  @userManager.State userInfo!: UserInfoType;
  @examDesc.State examDescription!: ExamDescriptionType;
  @examDesc.State loading!: boolean;
  @userManager.Getter getHomeRoute!: string;

  get hasExamDescription(): boolean {
    return Boolean(Object.keys(this.examDescription).length);
  }

  get getExamStatusConfig(): { slug: string; class: string } | undefined {
    return EXAM_STATUS_CONFIG[this.examDescription.status || ""];
  }

  get getExamDuration(): number {
    if (this.hasExamDescription) {
      const { created_at, finished_at } = this.examDescription;
      return this.getTime(finished_at) - this.getTime(created_at);
    }

    return 0;
  }

  get getRightAnswersIdsList(): number[] {
    return this.examDescription.right_answers.map(
      (el: RightAnswerType) => el.question_id
    );
  }

  /**
   * The check to status of the current exam is the success status
   */
  get hasSuccessExamStatus(): boolean {
    return this.examDescription.status === STATUS_SUCCESS;
  }

  mounted(): void {
    const { preview }: { preview?: string } = this.$route.query;
    this.showPreview = /true/.test(preview || "");
  }

  @userManager.Action userUpdateEmail!: (email: string | null) => Promise<void>;
  @examDesc.Action getCertData!: (id: string) => Promise<any>;

  public goBack(): void {
    if ((this.fromRoute as Route).name === this.getHomeRoute) {
      this.$router.back();
    } else {
      this.$router.push({ path: this.getHomeRoute });
    }
  }

  public async edsSignData(): Promise<void> {
    this.loadingCertData = true;

    const { id } = this.$route.params;
    const data: Record<string, any> = await this.getCertData(id);

    if (data.errors !== undefined) {
      this.signErrors = data.errors;
    } else {
      await this.pdf.create(data as UserCertificateDataType);
    }

    this.loadingCertData = false;
  }

  private getTime(datsStr: string): number {
    return new Date(datsStr).getTime();
  }
}
