import * as KEYS from "@/constants/pdfCertConstants";

export const PDF_FONTS = {
  OpenSans: {
    normal: `${window.location.origin}/fonts/Open_Sans/OpenSans-Regular.ttf`,
    bold: `${window.location.origin}/fonts/Open_Sans/OpenSans-Bold.ttf`,
  },
};

export const PDF_IMAGES = {
  [KEYS.IMAGE_NAMES.BG_CERT]: `${window.location.origin}/images/bg-cert.jpg`,
  [KEYS.IMAGE_NAMES.SIGN]: `${window.location.origin}/images/sign.png`,
};

export const PDF_DEFAULT_STYLES = {
  font: KEYS.FONT_OPEN_SANS,
  color: KEYS.COLOR_WHITE,
  fontSize: KEYS.FONT_SIZE.SMALL,
};
