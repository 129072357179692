import pdfMake from "pdfmake";
import * as KEYS from "@/constants/pdfCertConstants";
import {
  PDF_DEFAULT_STYLES,
  PDF_FONTS,
  PDF_IMAGES,
} from "@/config/pdfCertConfig";
import { UserCertificateDataType } from "@/types/API/UserCertificateDataType";
import { DateFormat } from "@/services/DateFormat/DateFormat";

/**
 * https://github.com/bpampuch/pdfmake
 * The default unit is "points".
 */
export class PDF {
  async create({
    finished_at,
    signer_name,
  }: UserCertificateDataType): Promise<void> {
    const docDefinition = {
      images: PDF_IMAGES,
      pageMargins: [80, 55, 55, 55],
      defaultStyle: PDF_DEFAULT_STYLES,
      content: [
        {
          image: KEYS.IMAGE_NAMES.BG_CERT,
          absolutePosition: { x: 0, y: 0 },
          width: 595, // A4 width in points
          height: 842, // A4 height in points
        },
        {
          text: this.getShortDate(finished_at),
          fontSize: KEYS.FONT_SIZE.MEDIUM,
        },
        {
          text: KEYS.TEXTS.CERT,
          fontSize: KEYS.FONT_SIZE.MEDIUM,
          margin: [0, 150, 0, 55],
        },
        {
          text: signer_name.toUpperCase(),
          fontSize: KEYS.FONT_SIZE.LARGE,
          bold: true,
          margin: [0, 0, 0, 30],
        },
        KEYS.CERTIFICATE_DESCRIPTION,
        {
          text: KEYS.TEXTS.DIRECTOR,
          margin: [0, 106, 0, 0],
          absolutePosition: { x: 80, y: 720 },
        },
        {
          image: KEYS.IMAGE_NAMES.SIGN,
          absolutePosition: { x: 390, y: 700 },
          width: 141,
          height: 80,
        },
      ],
    };

    await (pdfMake as Record<string, any>)
      .createPdf(docDefinition, null, PDF_FONTS)
      .download(KEYS.PDF_CERT_NAME);
  }

  private getShortDate(date: string): string {
    const dateformat = new DateFormat(date);
    return dateformat.setMonth.setYear.getResult.toLowerCase();
  }
}
